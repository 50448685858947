import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Flex } from './common/flex'
import { Space } from './common/space'
import { Text } from './common/text'
import { Grid, Row, Col, MdHiddenDown, MdHiddenUp } from './common/grid'

import { Hamburger } from './hamburger'

const linkStyles = css`
  display: inline-block;
  color: #69707b;
  padding: 8px;
  margin: 10px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  &:hover {
    text-decoration: none;
    color: #000;
  }
`

const mobileLinkStyles = css`
  display: block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #e2e2e2;
  }
`

const LogoLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const NavLink = styled(Link)`
  ${linkStyles};
`

const NavLinkA = styled('a')`
  ${linkStyles};
`

const navLinkActive = {
  color: '#000',
  borderBottomColor: '#9143fd',
}

const NavMobile = styled.nav`
  width: 100%;
  margin-top: 20px;
  background-color: #f7f7f7;
  padding: 10px 20px;
  border-radius: 5px;
`

const NavLinkMobile = styled(Link)`
  ${mobileLinkStyles};
`

const NavLinkMobileA = styled('a')`
  ${mobileLinkStyles};
`

const navLinkMobileActive = {
  color: '#9143fd',
}

export class Header extends React.Component {
  state = {
    opened: false,
  }

  render() {
    return (
      <header>
        <Space top={20} bottom={70} xBottom={40}>
          <Grid>
            <Row>
              <Col xs={6} md={2}>
                <Flex align="center" justify="flex-start" wide>
                  <LogoLink to="/">
                    <Text weight="600">Albedo Sports</Text>
                  </LogoLink>
                </Flex>
              </Col>
              <Col xs={6} md={8}>
                <Flex justify="center" wide>
                  <MdHiddenDown>
                    <nav>
                      <NavLink
                        to="/"
                        activeStyle={navLinkActive}
                        title="Chimpanzee"
                      >
                        Chimpanzee
                      </NavLink>
                      <NavLink
                        to="/produkte"
                        activeStyle={navLinkActive}
                        title="Produkte"
                      >
                        Produkte
                      </NavLink>
                      <NavLink
                        to="/pointofsale"
                        activeStyle={navLinkActive}
                        title="Point-of-Sale"
                      >
                        Point-of-Sale
                      </NavLink>
                      <NavLinkA
                        href="https://shop.emberg.net/registerFC/index/sValidation/H"
                        target="_blank"
                        title="Händler werden"
                      >
                        Händler werden
                      </NavLinkA>
                    </nav>
                  </MdHiddenDown>
                </Flex>
                <MdHiddenUp>
                  <Flex justify="flex-end" wide>
                    <Hamburger
                      opened={this.state.opened}
                      onClick={() => {
                        this.setState({ opened: !this.state.opened })
                      }}
                    />
                  </Flex>
                </MdHiddenUp>
              </Col>
              <Col xs={0} md={2}>
                <Flex justify="flex-end" wide>
                  <nav>
                    <NavLinkA
                      href="mailto:office@albedo-sports.com"
                      title="Schicken Sie uns eine e-Mail"
                    >
                      Kontakt
                    </NavLinkA>
                  </nav>
                </Flex>
              </Col>
            </Row>

            {this.state.opened && (
              <Row>
                <Col xs={12}>
                  <NavMobile>
                    <NavLinkMobile
                      to="/"
                      activeStyle={navLinkMobileActive}
                      title="Chimpanzee"
                    >
                      Chimpanzee
                    </NavLinkMobile>
                    <NavLinkMobile
                      to="/produkte"
                      activeStyle={navLinkMobileActive}
                      title="Produkte"
                    >
                      Produkte
                    </NavLinkMobile>
                    <NavLinkMobile to="/pointofsale" title="Point-of-Sale">
                      Point-of-Sale
                    </NavLinkMobile>
                    <NavLinkMobileA
                      href="https://shop.emberg.net/registerFC/index/sValidation/H"
                      target="_blank"
                      title="Händler werden"
                    >
                      Händler werden
                    </NavLinkMobileA>
                    <NavLinkMobileA
                      href="mailto:office@albedo-sports.com"
                      title="Schicken Sie uns eine e-Mail"
                    >
                      Kontakt
                    </NavLinkMobileA>
                  </NavMobile>
                </Col>
              </Row>
            )}
          </Grid>
        </Space>
      </header>
    )
  }
}
