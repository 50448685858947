import PropTypes from 'prop-types'
import styled from 'styled-components'

import { is } from './is'

export const Text = styled.div`
  width: 100%;

  ${is('align')`
    ${props => `text-align: ${props.align}`};
  `};

  ${is('color')`
    ${props => `color: ${props.color}`};
  `};

  ${is('weight')`
    ${props => `font-weight: ${props.weight}`};
  `};

  ${is('size')`
    ${props => `font-size: ${props.size}`};
  `};

  ${is('inline')`
    width: auto;
  `};
`

Text.propTypes = {
  inline: PropTypes.bool,
  color: PropTypes.string,
  weight: PropTypes.string,
  size: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
}
