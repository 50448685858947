import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import { Header } from './header'

import '../styles/normalize.css'
import '../styles/index.css'

const Main = styled.main`
overflow: hidden;
`

export const Layout = props => (
  <React.Fragment>
    <Helmet
      title={props.title}
      meta={[
        { name: 'description', content: props.description },
        { name: 'keywords', content: props.keywords },
      ]}
    >
      <html lang="de" />
    </Helmet>

    <Header title={props.title} />

    <Main>{props.children}</Main>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
