import PropTypes from 'prop-types'
import styled from 'styled-components'

import { is } from './is'

export const Flex = styled.div`
  display: flex;

  ${is('column')`
    flex-direction: column;
  `};

  ${is('wide')`
    width: 100%;
  `};

  ${is('align')`
    ${props => `align-items: ${props.align}`};
  `};

  ${is('justify')`
    ${props => `justify-content: ${props.justify}`};
  `};
`

Flex.propTypes = {
  wide: PropTypes.bool,
  column: PropTypes.bool,
  align: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]),
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
  ]),
}
