import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const bar = css`
  width: 30px;
  height: 2px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
`

const Wrapper = styled.div`
  display: inline-block;
  cursor: pointer;
`

const Bar1 = styled.div`
  ${bar};
  ${props => props.opened && 'transform: rotate(-45deg) translate(-6px, 5px);'};
`

const Bar2 = styled.div`
  ${bar};
  ${props => props.opened && 'opacity: 0;'};
`

const Bar3 = styled.div`
  ${bar};
  ${props => props.opened && 'transform: rotate(45deg) translate(-5px, -5px);'};
`

export const Hamburger = props => (
  <Wrapper onClick={props.onClick}>
    <Bar1 opened={props.opened} />
    <Bar2 opened={props.opened} />
    <Bar3 opened={props.opened} />
  </Wrapper>
)

Hamburger.propTypes = {
  opened: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
