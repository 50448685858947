import React from 'react'
import styled from 'styled-components'

import { Text } from './text'

const StyledH1 = styled.h1`
  font-size: 1.5em;
  font-weight: 600;

  @media only screen and (min-width: 992px) {
    font-size: 2.4em;
  }
`

const StyledH2 = styled.h2`
  font-size: 1.1em;
`

const StyledH3 = styled.h3`
  font-size: 1.4em;
  margin-bottom: 20px;
`

const StyledH4 = styled.h4`
  font-size: 1.1em;
  font-weight: 600;
`

export const H1 = props => (
  <Text {...props}>
    <StyledH1>{props.children}</StyledH1>
  </Text>
)

export const H2 = props => (
  <Text {...props}>
    <StyledH2>{props.children}</StyledH2>
  </Text>
)

export const H3 = props => (
  <Text {...props}>
    <StyledH3>{props.children}</StyledH3>
  </Text>
)

export const H4 = props => (
  <Text {...props}>
    <StyledH4>{props.children}</StyledH4>
  </Text>
)
