import styled, { css } from 'styled-components'

export const _Grid = styled.div`
  height: inherit;
  margin-left: auto;
  margin-right: auto;
`

export const Grid = styled(_Grid)`
  height: inherit;
  margin-left: auto;
  margin-right: auto;

  padding-left: 8px;
  padding-right: 8px;

  @media only screen and (min-width: 576px) {
    max-width: 544px;
  }
  @media only screen and (min-width: 768px) {
    max-width: 736px;

    padding-left: 16px;
    padding-right: 16px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 960px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1168px;
  }
`

export const _Row = styled.div`
  width: 100%;
  height: inherit;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`

export const Row = styled(_Row)`
  ${props => props.centered && css`
    justify-content: center;
  `};
`

export const _Col = styled.div`
  height: inherit;
  flex: 0 0 auto;
  box-sizing: border-box;

  ${props => props.bottom && css`
    margin-bottom: ${props.bottom}px;
  `};

  ${props => props.xs >= 0 && css`
    display: flex;
    flex-basis: ${props.xs / 12 * 100}%;
    max-width: ${props.xs / 12 * 100}%;

    ${props => props.xs === 0 && css`
      display: none;
    `};
  `};

  @media only screen and (min-width: 768px) {
    ${props => props.sm >= 0 && css`
      display: flex;
      flex-basis: ${props.sm / 12 * 100}%;
      max-width: ${props.sm / 12 * 100}%;

      ${props => props.sm === 0 && css`
        display: none;
      `};
    `};
  }

  @media only screen and (min-width: 992px) {
    ${props => props.md >= 0 && css`
      display: flex;
      flex-basis: ${props.md / 12 * 100}%;
      max-width: ${props.md / 12 * 100}%;

      ${props => props.md === 0 && css`
        display: none;
      `};
    `};
  }

  @media only screen and (min-width: 1200px) {
    ${props => props.lg >= 0 && css`
      display: flex;
      flex-basis: ${props.lg / 12 * 100}%;
      max-width: ${props.lg / 12 * 100}%;

      ${props => props.lg === 0 && css`
        display: none;
      `};
    `};
  }
`

export const Col = styled(_Col)`
  padding-right: 16px;
  padding-left: 16px;
`

export const MdHiddenDown = styled.div`
  display: none;

  @media only screen and (min-width: 991px) {
    display: block;
  }
`

export const MdHiddenUp = styled.div`
  @media only screen and (min-width: 992px) {
    display: none;
  }
`
