import PropTypes from 'prop-types'
import React from 'react'

import { Space } from './common/space'
import { Image } from '../components/common/image'
import { Grid, Row, Col } from '../components/common/grid'
import { H1, H2 } from '../components/common/heading'

export const Teaser = props => (
  <React.Fragment>
    <Space bottom={100} xBottom={50}>
      <Grid>
        <Row centered>
          <Col xs={12} md={8}>
            <Space bottom={10}>
              <H2 align="center" color="#9143fd">
                {props.title}
              </H2>
            </Space>
          </Col>
        </Row>
        <Row centered>
          <Col xs={12} md={8}>
            <H1 align="center">
              {props.description}
            </H1>
          </Col>
        </Row>
      </Grid>
    </Space>

    {props.imageName && (
      <Space bottom={100} xBottom={50}>
        <Grid>
          <Row centered>
            <Col xs={12}>
              <Image name={props.imageName} />
            </Col>
          </Row>
        </Grid>
      </Space>
    )}
  </React.Fragment>
)

Teaser.propTypes = {
  description: PropTypes.string.isRequired,
  imageName: PropTypes.string,
  title: PropTypes.string.isRequired,
}
