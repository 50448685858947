import PropTypes from 'prop-types'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        home: file(relativePath: { eq: "home.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        quick_mix: file(relativePath: { eq: "quick-mix.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        point_of_sale: file(relativePath: { eq: "point-of-sale.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        handel: file(relativePath: { eq: "handel.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energieriegel: file(relativePath: { eq: "energieriegel.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        proteinriegel: file(relativePath: { eq: "proteinriegel.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_drink: file(relativePath: { eq: "energy-drink.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        yippee: file(relativePath: { eq: "yippee.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_chews: file(relativePath: { eq: "energy-chews.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1136) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energieriegel_apricot: file(relativePath: { eq: "energie-riegel-apricot.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energieriegel_lemon: file(relativePath: { eq: "energie-riegel-lemon.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energieriegel_raisin: file(relativePath: { eq: "energie-riegel-raisin.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        proteinriegel_banana: file(relativePath: { eq: "protein-riegel-banana.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        proteinriegel_dates: file(relativePath: { eq: "protein-riegel-dates.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        proteinriegel_peanut: file(relativePath: { eq: "protein-riegel-peanut.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_chews_waldbeere: file(relativePath: { eq: "energy-chews-waldbeere.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        yippee_birne: file(relativePath: { eq: "yippee-birne.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        yippee_schokolade: file(relativePath: { eq: "yippee-schokolade.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        quickmix_protein: file(relativePath: { eq: "quickmix-protein.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        quickmix_energy: file(relativePath: { eq: "quickmix-energy.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_drink_grapefruit: file(relativePath: { eq: "energy-drink-grapefruit.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_drink_waldkirsche: file(relativePath: { eq: "energy-drink-waldkirsche.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        energy_drink_zitrone: file(relativePath: { eq: "energy-drink-zitrone.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        stand_small: file(relativePath: { eq: "stand-small.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        stand_middle: file(relativePath: { eq: "stand-middle.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        stand_big: file(relativePath: { eq: "stand-big.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        logo_for_the_planet: file(relativePath: { eq: "logo-for-the-planet.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        logo_vegetarian_society: file(relativePath: { eq: "logo-vegetarian-society.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        logo_vegan: file(relativePath: { eq: "logo-vegan.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data[props.name].childImageSharp.fluid} />}
  />
)

Image.propTypes = {
  name: PropTypes.string.isRequired,
}
