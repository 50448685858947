import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Space = styled.div`
  width: 100%;
  padding-top: ${props => props.top}px;
  padding-bottom: ${props => props.bottom}px;
  padding-right: ${props => props.right}px;
  padding-left: ${props => props.left}px;

  @media only screen and (max-width: 991px) {
    ${props => props.xTop && `padding-top: ${props.xTop}px;`};
    ${props => props.xBottom && `padding-bottom: ${props.xBottom}px;`};
    ${props => props.xLeft && `padding-left: ${props.xLeft}px;`};
    ${props => props.xRight && `padding-right: ${props.xRight}px;`};
  }
`

Space.propTypes = {
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  top: PropTypes.number,
  xBottom: PropTypes.number,
  xLeft: PropTypes.number,
  xRight: PropTypes.number,
  xTop: PropTypes.number,
};
