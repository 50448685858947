import PropTypes from 'prop-types'
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css } from 'styled-components'

import { Flex } from './common/flex'
import { Space } from './common/space'
import { Text } from './common/text'
import { Grid, Row, Col } from './common/grid'

const footerStyles = css`
  color: #69707b;
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap;
`

const Wrapper = styled.footer`
  padding-top: 50px;
  padding-bottom: 20px;
  margin-top: ${props => (props.bottom >= 0 ? props.bottom : 100)}px;
  background-color: #f7f7f7;
`

const FooterText = styled.div`
  ${footerStyles};
`

const Link = styled(GatsbyLink)`
  ${footerStyles};
`

const ALink = styled('a')`
  ${footerStyles};
`

export const Footer = props => (
  <Wrapper bottom={props.bottom}>
    <Grid>
      <Row>
        <Col xs={12} sm={6} md={3} bottom={30}>
          <div>
            <Space bottom={10}>
              <Text weight="600">Albedo OG</Text>
            </Space>
            <Space bottom={10}>
              <FooterText>Lindenstraße 4/2</FooterText>
              <FooterText>4540 Bad Hall</FooterText>
            </Space>
            <FooterText>
              <ALink href="tel:+4367762318234">+43 677 623 182 34</ALink>
            </FooterText>
            <FooterText>
              <ALink
                href="mailto:office@albedo-sports.com"
                title="Schicken Sie uns eine e-Mail"
              >
                office@albedo-sports.com
              </ALink>
            </FooterText>
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} bottom={30}>
          <div>
            <Space bottom={10}>
              <Text weight="600">Händler / Partner</Text>
            </Space>
            <Flex column>
              <ALink
                href="https://shop.emberg.net/registerFC/index/sValidation/H"
                target="_blank"
                alt="Händler werden"
              >
                Händler werden
              </ALink>
              <ALink
                href="https://shop.emberg.net/chimpanzee/energy-bar/"
                title="Energieriegel kaufen"
                target="_blank"
              >
                Energieriegel
              </ALink>
              <ALink
                href="https://shop.emberg.net/chimpanzee/protein-bar/"
                title="Proteinriegel kaufen"
                target="_blank"
              >
                Proteinriegel
              </ALink>
              <ALink
                href="https://shop.emberg.net/chimpanzee/drinks/"
                title="Gunpowder Drinks kaufen"
                target="_blank"
              >
                Gunpowder Drinks
              </ALink>
              <ALink
                href="https://shop.emberg.net/chimpanzee/shakes/"
                title="QuickMix Shakes kaufen"
                target="_blank"
              >
                QuickMix Shakes
              </ALink>
              <ALink
                href="https://shop.emberg.net/chimpanzee/gels/"
                title="Energy Gel kaufen"
                target="_blank"
              >
                Energy Gel
              </ALink>
            </Flex>
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} bottom={30}>
          <div>
            <Space bottom={10}>
              <Text weight="700">Produkte</Text>
            </Space>
            <Flex column>
              <Link
                to="produkte/energieriegel"
                title="Chimpanzee Energieriegel"
              >
                Energieriegel
              </Link>
              <Link
                to="produkte/proteinriegel"
                title="Chimpanzee Proteinriegel"
              >
                Proteinriegel
              </Link>
              <Link
                to="produkte/gunpowder-drinks"
                title="Chimpanzee Gunpowder Drinks"
              >
                Gunpowder Drinks
              </Link>
              <Link
                to="produkte/quickmix-shakes"
                title="Chimpanzee QuickMix Shakes"
              >
                QuickMix Shakes
              </Link>
              <Link to="produkte/yippee-bars" title="Chimpanzee Yippee Bar">
                Yippee Bar
              </Link>
              <Link to="produkte/energy-chews" title="Chimpanzee Energy Chews">
                Energy Chews
              </Link>
            </Flex>
          </div>
        </Col>

        <Col xs={12} sm={6} md={3} bottom={30}>
          <div>
            <Space bottom={10}>
              <Text weight="700">Mehr</Text>
            </Space>
            <Flex column>
              <Link to="/" title="Chimpanzee">
                Chimpanzee
              </Link>
              <Link to="/produkte" title="Produkte">
                Produkte
              </Link>
              <Link to="/pointofsale" title="Point-of-Sale">
                Point-of-Sale
              </Link>
              <ALink
                href="https://shop.emberg.net/registerFC/index/sValidation/H"
                target="_blank"
                alt="Händler werden"
              >
                Händler werden
              </ALink>
              <ALink
                href="mailto:office@albedo-sports.com"
                title="Schicken Sie uns eine e-Mail"
              >
                Kontakt
              </ALink>
              <Link to="/impressum" title="Impressum">
                Impressum
              </Link>
            </Flex>
          </div>
        </Col>
      </Row>
    </Grid>
  </Wrapper>
)

Footer.propTypes = {
  bottom: PropTypes.number,
}
