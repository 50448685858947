import { css } from 'styled-components'

/*
 * Usage
 * ${is('attr')`
 *   margin: 5px;
 * `}
 */
export const is = (...names) => (...args) => props =>
  names['every'](name => Boolean(props[name])) && css(...args)
